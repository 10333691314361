import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import axios from 'axios'
import queryString from 'query-string'
import {Base64} from 'js-base64'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import Container from '@material-ui/core/Container'
import NoSsr from '@material-ui/core/NoSsr'

const resetPageUseStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
        overflow: 'auto',
        maxHeight: '60vh',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxWidth: 800,
    },
    drawer: {
        maxWidth: '100%',
        maxHeight: '25%',
        flexShrink: 0,
    },
    drawerPaper: {
        height: '25%',
    },
    fullWidthTxt: {
        width: '60vw',
    },
    button: {
        marginTop: '16px',
        marginRight: theme.spacing(1),
    },
    card: {
        minWidth: 275,
    },
    cardMargin: {
        marginTop: '1rem'
    },
    link: {
        margin: theme.spacing(1),
    },
    outputBox: {
        wordBreak: 'break-all'
    },
    error: {
        color: 'red'
    }
}));

const ResetPwPage = ({ location }) => {
    const classes = resetPageUseStyles();
    const [isTimeout, setIsTimeout] = useState(false);
    const [value, setValue] = useState({});
    const txtNewPassword = useRef();
    const txtConfirmNewPassword = useRef();
    let passData = {};
    let postData = {};

    useEffect(() => {
        let encoded = queryString.parse(location.search);

        if (!encoded.d) {
            setIsTimeout(true);
        } else {
            let decoded = JSON.parse(Base64.decode(encoded.d));
            // console.log(decoded);
            let requestDate = decoded.Timestamp * 1000;
            let availableDate = requestDate + (30 * 60 * 1000); //30mins
            let nowTS = (+new Date());
            passData = encoded.d;

            if (nowTS > availableDate) {
                setIsTimeout(true);
            } else {
                // Check is uesed
                axios({
                    method: 'GET',
                    url: `http://ec2-52-32-155-86.us-west-2.compute.amazonaws.com:5678/resources/${encoded.d}`
                })
                .then((res) => {
                    if (res.status === 200 && res.hasOwnProperty('data') ) {
                        if ( res.data.isUsed ) {
                            setIsTimeout(true);
                        }
                    }
                })
                .catch((e) => {

                });
            }

        }
    });

    function handleChange() {
        const _txtNewPassword = txtNewPassword.current;
        const _txtConfirmNewPassword = txtConfirmNewPassword.current;

        if (_txtNewPassword.validity.valid) {
            _txtNewPassword.parentElement.parentElement.nextSibling.className = `${classes.error}`
            _txtNewPassword.parentElement.parentElement.nextSibling.innerHTML = ""
        } else {
            _txtNewPassword.parentElement.parentElement.nextSibling.className = `${classes.error} active`
            _txtNewPassword.parentElement.parentElement.nextSibling.innerHTML = "Password must be more than 6 length."
        }

        if (_txtConfirmNewPassword.value === _txtNewPassword.value) {
            _txtConfirmNewPassword.parentElement.parentElement.nextSibling.className = `${classes.error}`
            _txtConfirmNewPassword.parentElement.parentElement.nextSibling.innerHTML = ""
            postData = { pass: _txtNewPassword.value, conPass: _txtConfirmNewPassword.value };
        } else {
            _txtConfirmNewPassword.parentElement.parentElement.nextSibling.className = `${classes.error} active`
            _txtConfirmNewPassword.parentElement.parentElement.nextSibling.innerHTML = "Please ensure it is the same as above New Password."
        }
    }

    function cancelReset() {
        txtNewPassword.current.value = ""
        txtConfirmNewPassword.current.value = ""
    }

    function submitReset(e) {
        e.preventDefault();
        let valNewPw = postData.pass;
        let valConNewPw = postData.conPass;
        let _passData = passData;

        if ( valNewPw == undefined || valConNewPw == undefined ||
            valNewPw == '' || valConNewPw == '' || (valNewPw != valConNewPw) ) {
            console.log(`[ERROR] New password missing or New password not matched`);
            return false;
        } else {
            let oob = "acidrainwor1d2Ol81st";
            // console.log(`debug message`);
            // console.log(_passData);
            axios({
                method: 'POST',
                url: `${process.env.MEMBERSHIP_PROXY_ENDPOINT}/users/password/reset`,
                data: {
                    "data": {
                        'd': _passData,
                        'password': valNewPw
                    }
                }
            })
            .then((res) => {
                // console.log(res)
                if (res.status === 200) {
                    // Save key
                    axios({
                        method: 'POST',
                        url: `http://ec2-52-32-155-86.us-west-2.compute.amazonaws.com:5678/resources`,
                        data: {
                            "id": _passData,
                            "isUsed": true,
                            "created_date": +(new Date())
                        }
                    })
                    .then((res) => {
                        // redirect to success
                        navigate('/reset/success');
                    });
                } else {
                    // redirect to failed
                    navigate('/reset/failed');
                }
            })
            .catch((err) => {
                // console.log(err)
                navigate('/reset/failed');
            });
        }
    }

    return (
        <NoSsr>
            <Container className={classes.root}>
                <Paper className={classes.container} elevation={0}>
                    <Grid item xs={12}>
                        <h1>Aither Member</h1>
                    </Grid>
                    {isTimeout === false &&
                        <Grid item xs={12}>
                            <h3>Reset Password</h3>
                            <form className={classes.root} noValidate autoComplete="off">
                                <TextField name="new_password" id="new_password"
                                    label="New Password"
                                    type="password" inputRef={txtNewPassword}
                                    fullWidth
                                    inputProps={{
                                        pattern: "[0-9A-Za-z\~\!\@\#\$\%\^\&\*\-\_\+\=]{6,20}"
                                    }}
                                    onChange={handleChange} />
                                <span className={classes.error}></span>
                                <br/>
                                <TextField name="new_password_confirm" id="new_password_confirm"
                                    label="Confirm New Password"
                                    type="password" inputRef={txtConfirmNewPassword}
                                    inputProps={{
                                        pattern: "[0-9A-Za-z\~\!\@\#\$\%\^\&\*\-\_\+\=]{6,20}"
                                    }}
                                    fullWidth
                                    onChange={handleChange} />
                                <span className={classes.error}></span>
                                <br/>
                                <Button variant="contained" className={classes.button} onClick={cancelReset}>Cancel</Button>
                                <Button variant="contained" color="primary" className={classes.button} onClick={submitReset}>Submit</Button>
                            </form>
                        </Grid>
                    ||
                        <Grid item xs={12}>
                            <h3>Invalid Request</h3>
                        </Grid>
                    }
                </Paper>
            </Container>
        </NoSsr>
    )
}

export default ResetPwPage
